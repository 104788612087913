import * as React from "react";
import Layout from "../../components/Layout";
import GeneralTab from "../../components/HTabs";
import { Seo } from "../../components/seo";
import Counter from "../../components/Counter";
import FAQAccord from "../../components/GFlush";
import Document from "../../components/Document";
// import Hero from "../../components/Hero";
import About from "../../components/About";
import ImgContent from "../../components/GSTTemp/ImgContent";
import ContentImg from "../../components/GSTTemp/ContentImg";
import Cta from "../../components/Cta";
import Package from "../../components/GSTTemp/Package";

export default function AnnualFilingLLP() {
  // banner Slider
  // const AnnualFilingSlider = [
  //   {
  //     header_color: true,
  //     content_color: true,
  //     marginTop: 30,
  //     background: `linear-gradient(139.4deg, #0D324D, #7F5A83`,
  //     header: `Annual Filing for LLP`,
  //     content: `TODAYFILINGS, India's largest Annual Filing for LLP service in india,
  //   can assist you with your business.`,
  //     image: "/imgs/business/sliders/subsidiary-slider.png",
  //     alt_tag: "Best Online Annual Filing for LLP in Hosur",
  //   },
  // ];

  /* Package Data */
  const PackageCompData = {
    header: `Annual Filing For LLP`,
    buyBtnLink: `#pricing-buy`,
    price: `4999`,
    content: [
      {
        points: `PAN & TAN Registration `,
        icon: "/imgs/icons/gst.png",
        alt_tag: "PAN & TAN Registration",
        background: "",
        plus: "+",
      },
      {
        points: `PF + ESIC + Professional Tax`,
        icon: "/imgs/icons/itr_filing.png",
        alt_tag: "TODAYFILINGS ITR E-filing service",
        background: "bg-f78acb",
        plus: "+",
        plus_dnone: "d-none d-md-block",
      },
      {
        points: `MSME Registration`,
        icon: "/imgs/icons/enterprises.png",
        alt_tag: "MSME Registration",
        background: "bg-eb6b3d",
        plus: "+",
      },
      {
        points: `Tax Consultancy Service `,
        icon: "/imgs/icons/consultant.png",
        alt_tag: "Tax Consultancy Service",
        background: "bg-c679e3",
      },
    ],
  };
  /* About Data Without Form*/
  const AnnualFilingAboutData = {
    title: `Online Annual Filing for LLP`,
    sub_title: `Annual Filing for LLP services in just 7 Days`,
    paragraph: `In the commercial world, a Limited Liability Partnership, or
    LLP, has a unique position. As a result, a corporation must
    regularly submit its yearly compliance to the Ministry of
    Corporate Affairs (MCA) in order to preserve its active
    position. Whether the business is open for business or not,
    the LLP must file an annual compliance report. Two separate
    forms must be filed annually for LLP compliance one is for
    the Annual Return and the other is for the Report of
    Accounts and Solvency.`,
    header: 'Benefits Of Annual Compliance For LLP',
    points: [
      `Avoid Penalties`,
      `Greater Reputation`,
      `Financial Worth Records`,
      `Simple conversion and completion`,
    ],
  };
  /* FaQ Data */
  var AnnualFilingFAQ = [
    {
      header: 'What is the requirement for LLP audits?',
      body: [
        {
          content: `The LLP whose LLP capital contribution surpasses Rs. 25 lakhs or
          whose annual turnover exceeds Rs. 40 lakhs`,
          icon: false,
        },
      ],
    },
    // 2
    {
      header: 'What are the fundamental LLP compliances?',
      body: [
        {
          content: `For LLP compliances every year, there are 3 essential compliances
          required,`,
          icon: false,
        },
        {
          content: `Annual return.`,
          icon: true,
        },
        {
          content: `Financial reports or Statements of the LLP.`,
          icon: true,
        },
        {
          content: `IT Returns Filings.`,
          icon: true,
        },
      ],
    },
    {
      header: `What is the Audit necessity for LLP?`,
      body: [
        {
          content: `The LLP whose LLP capital contribution surpasses Rs. 25 lakhs or
          whose annual turnover exceeds Rs. 40 lakhs.`,
          icon: false,
        },
      ],
    },
    // 3
    {
      header: `If the status is SRN, or "Sent for Resubmission," what should be
        done?`,
      body: [
        {
          content: ` You must resubmit your E-Form to fix or alter the errors noted by
          the relevant MCA office if the status is SRN or "Sent for
          Resubmission."`,
          icon: false,
        },
      ],
    },
    // 4
    {
      header: `"Statement of Accounts and Solvency" – what does that mean?`,
      body: [
        {
          content: `Every LLP is required to submit LLP Form 8, also known as a
          "Statement of Accounts and Solvency," once a year. This form
          contains information on the LLP's financial's, such as
          asset-liability information.`,
          icon: false,
        },
      ],
    },
    //5
    {
      header: `How long does the LLP registration process take?`,
      body: [
        {
          content: `After submitting all required documents, the company registration
          process can be finished in 15 to 35 working days.`,
          icon: false,
        },
      ],
    },
    {
      header: `Why is it necessary for the LLPs to comply with the ROC Compliance?`,
      body: [
        {
          content: `The LLPs are corporate entities and are operated by the legal rules and 
          the procedures that are the stated in LLP Act 2008. Irrespective of the turnover the
           LLPs have to file the annual returns giving details on the management on the financial
            performance. Any delay in the same attracts a heavy penalty.`,
          icon: false,
        },
      ],
    },
    {
      header: `Is a board meeting held for the LLPs?`,
      body: [
        {
          content: `The Board meeting is conducted by the Board of Directors, here no BOD is involved 
          in the LLPs instead the designated partners run the whole business and are also responsible for the compliance`,
          icon: false,
        },
      ],
    },
    {
      header: ` What is the compliance exemption for the LLPs?`,
      body: [
        {
          content: `There are many privileges for the LLPs as compared to other companies there are exemptions 
          from maintaining the minutes' books, statutory register, annual general meeting as well as flexible rates.`,
          icon: false,
        },
      ],
    },
    {
      header: `What are the compliances for the partners in LLP?`,
      body: [
        {
          content: `The Partners need to comply with the annual return filing with the MCA, filing the statement of accounts.`,
          icon: false,
        },
      ],
    },
  ];
  /* Tabs Data */
  const AnnualFilingTabsData = {
    id: 'features',
    heading: 'Benefits of Annual Compliance for LLP',
    paragraph: `The accounting department is one of the most important parts of
    a company's organizational structure. our consulting services
    are`,
    body: [
      {
        tab_title: 'Financial Worth',
        tab_content: [
          {
            content_title: `Financial Worth Records`,
            content_paragraph: [],
            points: [
              `Companies have access to the Limited Liability
            Partnership's forms. Therefore, the authorized party may
            also visit or audit the financial worth when engaging into
            significant initiatives or contracts.`,
              `An interested person or party may request a copy of the
            LLP's annual filing to see the company's financial
            standing and capabilities.`,
            ],
          },
        ],
        content_img: '/imgs/assited/financial-worth.png',
        alt_img: 'TODAYFILINGS-Financial annual filing',
      },
      {
        tab_title: 'Higher Reputation',
        tab_content: [
          {
            content_title: 'Greater Reputation',
            content_paragraph: [],
            points: [
              `Formal compliance is a prerequisite for any organization
            or company. Anyone can access the Master Data of the LLP
            on the MCA site to see the status of the Limited Liability
            Partnership annual filing.`,
              `A key factor in determining the legitimacy of a corporate entity
            for loan approvals or other similar needs is annual
            compliance.`,
            ],
          },
        ],
        content_img: '/imgs/assited/credit.png',
        alt_img: 'TODAYFILINGS-Reputation annual filing',
      },
      {
        tab_title: 'Easy Conversion',
        tab_content: [
          {
            content_title: 'Simple conversion and completion',
            content_paragraph: [],
            points: [
              ` The conversion of the LLP into any other type of
            corporation or organization requires an annual filing. The
            conversion process is made easier by the regular
            compliance reports.`,
              `The same employee in the event that an LLP firm is closed.
            The Registrar may demand that the annual compliance and
            LLP filing fee be submitted even if the LLP was not
            active.`,
            ],
          },
        ],
        content_img: '/imgs/assited/easy-conversion.png',
        alt_img: 'TODAYFILINGS-LLP conversion',
      },
      {
        tab_title: 'Avoid Penalties',
        tab_content: [
          {
            content_title: 'Keep your status active to avoid fines',
            content_paragraph: [],
            points: [
              `The Limited Liability Partnership may be declared inactive
            or given default status in the event of consecutive yearly
            filing defaults.`,
              `The partners may also be declared in default and barred from 
            future appointments in an LLP or another business. Consequently,
            filing the annual return is required.`,
            ],
          },
        ],
        content_img: '/imgs/assited/penalty.png',
        alt_img: 'TODAYFILINGS-Avoiding penalties',
      },
    ],
  };

  /* Document Section Data */
  const AnnualFilingDocData = {
    id: 'ann-fil-doc',
    heading: `Annual Filing of LLP`,
    sub_heading: `In contrast to all other forms of companies, LLPs are required
    by law to declare their financial year as running from April 1
    to March 31 each year. As a result, the deadlines for the LLP
    annual return and the Account & Solvency Report are both May
    30 and October 30 respectively. LLPs are required to file IT
    returns every year in addition to the MCA annual return.`,
    doc_contents: [
      {
        col: `col-lg-6 col-md-6`,
        delay: '100',
        img: `/imgs/icons/it/fin-accounting.png`,
        alt_tag: `LLP Form 8`,
        header: `LLP Form 8`,
        description: `With a certain fee, Form 8 must be submitted within 30 days.
        Statements of Solvency, Income & Expenditure Statements, and
        Statements of Accounts are all included. It must be
        certified by an accredited chartered accountant and bear the
        digital signatures of two of the company's appointed
        partners.`,
      },
      {
        col: `col-lg-6 col-md-6`,
        delay: '200',
        img: `/imgs/icons/it/management-acc.png`,
        alt_tag: `LLP Form 11`,
        header: `LLP Form 11`,
        description: `Every year on May 30th, LLP Form 11 filing is required. This
        form must be submitted by all LLPs within 60 days of the end
        of each financial year. It contains data on the overall
        number of partners, the sum of contributions made by all
        partners, and the total number of body corporations that are
        partners.`,
      },
      {
        col: `col-lg-6 col-md-6`,
        delay: '300',
        img: `/imgs/icons/it/tax-acc.png`,
        alt_tag: `Income Tax Return Filing`,
        header: `Income Tax Return Filing`,
        description: `LLPs had to submit IT returns along with Form ITR 5. By
        going to the income tax department's official website, this
        form can be submitted online. The LLP can file the tax on
        July 31st if a tax audit is not necessary. The LLP must file
        its taxes by September 30th in order to be subject to an
        audit.`,
      },
      {
        col: `col-lg-6 col-md-6`,
        delay: '400',
        img: `/imgs/icons/it/cost-acc.png`,
        alt_tag: `LLP Tax Audit`,
        header: `LLP Tax Audit`,
        description: `Since LLP is a distinct legal organization, it is entirely
        up to the Designated Partners to keep an accurate report of
        finances and submit annual reports to the MCA each fiscal
        year. Auditing the accounts is not necessary unless the
        relevant LLP's contribution reaches Rs. 25 lakhs or its
        annual turnover.`,
      },
    ],
  };
  /* Img Content Component Data */
  const AnnualFilingIcData = {
    id: 'dsc',
    mt_div: 'mt-5',
    mt_img: 'mt-1',
    header: "Documents needed for LLP's Annual Compliance Filing",
    image: '/imgs/doc-req.png',
    alt_tag: 'Annual Filing Documents Required',
    points: [
      {
        head: '',
        content: `PAN card and certificate of incorporation for LLP`,
        icon: true,
      },
      {
        head: '',
        content: `Together with any report or agreement, the LLP Agreement.`,
        icon: true,
      },
      {
        head: '',
        content: `If any Financial report or Statement of LLP is required, it
        must be properly signed by the allocated partners.`,
        icon: true,
      },
      {
        head: '',
        content: `Using the LLP's name and identification number as a proof of
        ownership.`,
        icon: true,
      },
      {
        head: '',
        content: `Address of the LLP's registered office and its industry
        categorization.`,
        icon: true,
      },
      {
        head: '',
        content: `The LLP's main lines of business and total accountability
        for the LLP partners' contributions.`,
        icon: true,
      },
      {
        head: '',
        content: `Aspects of the Partners and Partners selected by the LLP
        Partner and Designated Partner Evaluation All LLP partners
        have endorsed the total contribution.`,
        icon: true,
      },
      {
        head: '',
        content: `Information on any fines imposed on the LLP also characteristics of an LLP or corporation where partners
        serve as directors or partners.`,
        icon: true,
      },
    ],
  };

  /*  Content Img Component Data */
  const AnnualLlpCiData = {
    id: 'document',
    background: '',
    mt_div: '',
    header: 'Checklist for Annual Compliance of LLP Filing',
    paragraph: '',
    points: [
      {
        content: `It is necessary to submit annual reports to the Registrar of
          Companies (ROC).`,
        icon: true,
      },
      {
        content: `Annual returns for LLPs must be submitted with Form 11 in
          the required manner.`,
        icon: true,
      },
      {
        content: `Annual returns must be filed no later than two months or
          sixty days after the end of the year. This could be
          submitted on May 30 of each year.`,
        icon: true,
      },
      {
        content: ` Every LLP that is registered must comply with the annual
          compliance requirement, even if no business is being
          conducted. If the LLP has been dissolved or even if there
          isn't a company bank account, it must be obtained.`,
        icon: true,
      },
    ],
    image: '/imgs/gifs/img3.gif',
    alt_tag: 'Checklist for Annual Compliance of LLP Filing',
  };

  return (
    <div>
      <Seo
        title='Annual Filing for LLP'
        keywords='Keywords'
        description='Description'
        name='TODAYFILINGS'
        type='Website'
        author='TODAYFILINGS'
      />
      <Layout>
        <main id='main'>
          {/* <Hero sliders={AnnualFilingSlider} /> */}
          <Package packageData={PackageCompData} />

          <About about_data={AnnualFilingAboutData} />

          <Counter />

          <ImgContent item={AnnualFilingIcData} />

          <Document docSecData={AnnualFilingDocData} />

          <ContentImg CISection_data={AnnualLlpCiData} />

          <GeneralTab HTabsData={AnnualFilingTabsData} />
          <Cta />
          <FAQAccord items={AnnualFilingFAQ} />
        </main>
      </Layout>
    </div>
  );
}
